/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Linkedin from 'img/linkedin.inline.svg'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.grey};
    @media (min-width: 992px) {
      font-size: 100px;
      line-height: 100px;
    }
  }
`

const Member = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary} !important;
  }
  & .job-title {
    color: ${({ theme }) => theme.color.secondary} !important;
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
      text-align: center;
    }

    & .job-title {
      font-size: ${({ theme }) => theme.font.size.small};
      text-align: center;
    }
  }

  @media (max-width: 349px) {
    & h2 {
      min-height: 38px;
    }
  }
`

const ImageWrapper = styled.div``

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 179px;
    height: 179px;
  }

  @media (max-width: 991px) {
    width: 139px;
    height: 139px;
  }

  @media (max-width: 575px) {
    width: 91px;
    height: 91px;
  }
`

const SvgWrapper = styled.div`
  bottom: 10px;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 576px) {
    right: 5px;
  }

  @media (max-width: 575px) {
    right: -5px;
    & svg {
      width: 40px;
      height: 40px;
    }
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team = ({ fields }: TeamProps) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <Content content={fields.description} className="mb-5" />
            {fields.members?.map((member, index) => (
              <div className="col-6 col-md-4 col-xl-3 h-100 mb-5" key={index}>
                <Member className="d-flex flex-column align-items-center">
                  <ImageWrapper className="position-relative">
                    <Image
                      image={member?.avatar}
                      alt={member?.name || ''}
                      className="position-relative rounded-circle mb-3"
                    />

                    <SvgWrapper className="position-absolute">
                      <a
                        className="text-hidden"
                        href={member?.linkedin}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Linkedin
                        <Linkedin className="rounded-circle" />
                      </a>
                    </SvgWrapper>
                  </ImageWrapper>

                  <h2 className="text-primary font-weight-bold">
                    {member?.name}
                  </h2>
                  <span className="job-title text-center">
                    {member?.jobtitle}
                  </span>
                </Member>
              </div>
            ))}
            <ParseContent
              content={fields.outro}
              className="mt-lg-5 mt-2 text-primary"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Team
